@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
  padding: 0;
  margin: 0;
  background-color: #f5f8fa;
  font-family: 'Poppins', sans-serif;
}
a {
    text-decoration: none !important;
}
.aside-dark .hover-scroll-overlay-y:hover {
    scrollbar-color: #41416e transparent;
}
.aside-fixed .wrapper {
    transition: padding-left .3s ease;
    padding-left: 265px;
}
.aside-dark .hover-scroll-overlay-y {
    --scrollbar-space: 0.4rem;
    scrollbar-color: #3b3b64 transparent;
}
.hover-scroll-overlay-y:hover {
    overflow-y: overlay;
}
div:hover, main:hover, ol:hover, pre:hover, span:hover, ul:hover {
    scrollbar-color: #e9edf1 transparent;
}
.hover-scroll-overlay-y {
    overflow-y: hidden;
    position: relative;
    --scrollbar-space: 0.5rem
}

.hover-scroll-overlay-y::-webkit-scrollbar {
    width: calc(.4rem + var(--scrollbar-space))
}

.hover-scroll-overlay-y::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border-right: var(--scrollbar-space) solid transparent
}

.hover-scroll-overlay-y:hover {
    overflow-y: overlay
}

@-moz-document url-prefix() {
    .hover-scroll-overlay-y {
        overflow-y: scroll;
        position: relative
    }
}

.scroll-ps {
    padding-left: .4rem !important
}

.scroll-ms {
    margin-left: .4rem !important
}

.scroll-pe {
    padding-right: .4rem !important
}

.scroll-me {
    margin-right: .4rem !important
}

.scroll-px {
    padding-left: .4rem !important;
    padding-right: .4rem !important
}

.scroll-mx {
    margin-left: .4rem !important;
    margin-right: .4rem !important
}
.aside-dark .hover-scroll-overlay-y {
    --scrollbar-space: 0.4rem;
    scrollbar-color: #3b3b64 transparent
}

.aside-dark .hover-scroll-overlay-y::-webkit-scrollbar-thumb {
    background-color: #3b3b64
}

.aside-dark .hover-scroll-overlay-y:hover {
    scrollbar-color: #41416e transparent
}

.aside-dark .hover-scroll-overlay-y:hover::-webkit-scrollbar-thumb {
    background-color: #41416e
}
.hover-scroll-overlay-y {
    overflow-y: hidden;
    position: relative;
    --scrollbar-space: 0.5rem;
}
.Page-inner-container {
  width: 100%;
  float: left;
  padding: 30px 30px;
  min-height: calc(100vh - 123px);
}
.card {
    border: 0;
    box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
}
.card.card-xl-stretch {
    height: calc(100% - var(--bs-gutter-y));
}
.card .card-body {
        padding: 1rem 1.25rem;
}
.py-lg-13 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
}
.text-dark {
    color: #181c32 !important;
}
.text-muted {
    color: #a1a5b7 !important;
}
.h-100px {
    height: 100px !important;
}
.w-100px{
    width: 100px !important;
}
.fillImage{
    object-fit: cover;
}
.page-layout.wrapper {
    transition: padding-left .3s ease;
    padding-left: 265px;
}
.cookiesContent button.accept,
.cookiesContent button.reschdeule{
    cursor: pointer;
}
.ant-form-item .ant-input-number-handler-wrap {
   display: none;
  }
  .teacher-profile-container .ant-input-number {
    width: 100%;
  }
.ant-modal-mask {
    z-index: 19998;
  }
.ant-modal-wrap {
    z-index: 19999;
  }
 /* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #0a0909 !important;
}  */
@media (max-width:991.98px) {
    .page-layout.wrapper {
     padding-left: 0;
    }
    .header-fixed .header {
     left: 0;
    }
    .header .header-menu {
        display: flex;
 }
 .header-menu .Header-menu-Icon {
     display: none;
 }
 .header-menu .Header-menu-Icon {
    display: block;
    left: 0;
    margin-top: -6px;
    width: inherit;
}
.aside {
    display: block;
    left: -265px;
}
.menu-close .aside {
    left: 0;
}
.menu-item .menu-link {
    padding: 7px 10px;
    font-size: 13px;
}
.aside .aside-logo {
    display: block;
}
.aside .aside-logo {
    display: flex;
}
 }