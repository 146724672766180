.container {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.dropdown-container {
  margin-bottom: 20px;
}

.dropdown {
  padding: 10px;
  font-size: 1rem;
  margin-left: 10px;
}

.shift-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.shift-table th, .shift-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.shift-table th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;         
  z-index: 1;      
}

.shift-table td {
  background-color: white;
}


.time-dropdown {
  padding: 5px;
  font-size: 0.9rem;
}

.edit-btn, .save-btn {
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;
}

.edit-btn {
  background-color: #007bff;
  color: white;
  border: none;
}

.save-btn {
  background-color: #28a745;
  color: white;
  border: none;
}
.button-group {
  display: flex;
  gap: 15px; 
  margin-top: 10px; 
}

.action-button {

  font-size: 14px;
}
