.teacher-profile-container .card {
    border: 0;
    box-shadow: 0 0 20px 0 rgba(76,87,125,.02);
}
.teacher-profile-container .card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 70px;
    padding: 0 2.25rem;
    background-color: transparent;
    border-bottom: 1px solid #eff2f5;
}
.teacher-profile-container .card .card-header .card-title, .card .card-header .card-title .card-label {
    font-weight: 500;
    font-size: 1.275rem;
    color: #181c32;
}

.teacher-profile-container .fw-bolder {
    font-weight: 600 !important;
}
.teacher-profile-container div, main, ol, pre, span, ul {
    scrollbar-width: thin;
    scrollbar-color: #eff2f5 transparent;
}
.teacher-profile-container .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}
.teacher-profile-container .col-form-label {
    padding-top: calc(.75rem + 1px);
    padding-bottom: calc(.75rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    font-weight: 500;
    line-height: 1.5;
    color: #3f4254;
}
.teacher-profile-container .image-input {
    position: relative;
    display: inline-block;
    border-radius: .475rem;
    background-repeat: no-repeat;
    background-size: cover;
}
.teacher-profile-container .image-input.image-input-outline .image-input-wrapper {
    border: 3px solid #fff;
    box-shadow: 0 .1rem 1rem .25rem rgba(0,0,0,.05);
}
.teacher-profile-container .btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
    border: 0;
}
.teacher-profile-container .btn.btn-icon.btn-circle {
    border-radius: 50%;
}
.teacher-profile-container .form-text {
    margin-top: .5rem;
    font-size: .925rem;
    color: #a1a5b7;
}
.teacher-profile-container .fw-bold {
    font-weight: 500 !important;
}
.teacher-profile-container .fs-6 {
    font-size: 1.075rem !important;
}
.teacher-profile-container .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
}
.teacher-profile-container .form-control.form-control-solid {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color .2s ease,background-color .2s ease;
}
.teacher-profile-container .fv-plugins-message-container.invalid-feedback, .fv-plugins-message-container.valid-feedback {
    display: block;
    font-weight: 400;
}
.teacher-profile-container .form-control {
    box-shadow: none !important;
}
.teacher-profile-container .form-control-lg {
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: .825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: .475rem;
}
.teacher-profile-container .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.teacher-profile-container .fv-plugins-icon-container {
    position: relative;
}
.teacher-profile-container .card .card-footer {
    padding: 2rem 2.25rem;
    background-color: transparent;
    border-top: 1px solid #eff2f5;
}
.teacher-profile-container .py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
.teacher-profile-container .ant-input-number-input,
.teacher-profile-container .ant-input{
    background-color: #f5f8fa !important;
    border: 1px solid #f5f8fa !important;
}
.teacher-profile-container .ant-select:not(.ant-select-customize-input) 
.teacher-profile-container .ant-select-selector{
    background-color: #f5f8fa !important;
    border: 1px solid #f5f8fa !important;
}
.teacher-profile-container .ant-input-number{
    background-color: #f5f8fa !important;
    border: 1px solid #f5f8fa !important;
}
.teacher-profile-container .ant-input-number:focus, 
.teacher-profile-container .ant-input-number-focused {
    border-color: #f5f8fa !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0) !important;
    border-right-width: 1px !important;
    outline: 0;
  }
.teacher-profile-container .ant-input:focus, .ant-input-focused {
    border-color: #f5f8fa !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0) !important;
}
.ant-select:not(.ant-select-customize-input) 
.ant-select-selector {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
}
.ant-picker{
    background-color: #f5f8fa !important;
    border: 1px solid #f5f8fa !important; 
}
.ant-picker-focused{
    border-color: #f5f8fa !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0) !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f5f8fa !important;
    border-right-width: 1px !important;
  }

.custom-modal .ant-modal-content {
    width: 120%;
  }
  