.ae-form-title {
    font-size: 15px;
    padding-left: 1rem;
    font-weight: 700;
}
.ae-form-wrapper {
    padding: 1rem;
    /* display: grid; */
    grid-template-columns: 1fr 1fr;

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
    }
}
.ae-form-row {
    padding-bottom: 1.25rem;

    &:nth-child(odd) {
        border-right: 1px solid #272532;
        margin-right: 2rem;
        padding-right: 1rem;

        @media (max-width: 992px) {
            border-right: none;
            margin-right: 0;
            padding-right: 0;
        }
    }
    
    .ae-form-question {
        color: black;
        font-size: 14px;
        margin-bottom: 10px;
    }
 
}
.ae-form-answer {
    color: #E82C86;
    font-size: 12px;
    margin-bottom: 0;
}

.custom-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px; 
  }
  
  .custom-label {
    margin-bottom: 8px; 
    font-weight: 800;
  }
  
  .custom-input {
    width: 100%; 
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color 0.2s ease; 
    background-color: 0.2s ease;
   
    transition-duration: 0.2s, 0.2s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
    padding-left: 10px;
 
        padding-top: 4px;
        padding-right: 11px;
        padding-bottom: 4px;
  }
  