.teachers-slots-container .demo-schedule    .cookiesContent{
    width: 550px;
}
.title-container {
    width: 100%;
    float: left;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.interested-course{
    text-align: left;
    font-size:  14px;
    letter-spacing: 0;
    color: #1c264b;
    opacity: .6;
}
.intersetCourse {
    float: left;
width: 100%;
    background: #fff;
border-radius: 10px;
box-shadow: 0 3px 6px #00000014;
border-radius: 5px;
display: flex;
align-items: center;
margin-bottom: 10px;
padding: 0 7px;
min-height: 54px;
border: solid 1px #f0f0f0;
}
/* .intersetTxt {
    word-break: break-all;
} */
.course-list .row {
    margin-right: -5px;
    margin-left: -5px;
  }
.course-list .col-lg-4{
    height: 100%;
    padding-left: 5px;
padding-right: 5px;
}
.interested-course {
    width: 100%;
    float: left;
}
.course-list {
    width: 100%;
    float: left;
    padding: 15px 0;
}
.course-mode .ant-radio-wrapper{
    background: #fff 0 0 no-repeat padding-box !important;
    box-shadow: 0 3px 6px #00000014 !important;
    border-radius: 5px !important;
    opacity: 1;
    font-size: 13px;
color: #000;
    border: 1px solid #fff;
    height: auto;
    min-width: 100px;
    padding: 5px 10px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
justify-content: center;
border: solid 1px #f0f0f0;
margin-left: 5px;
margin-right: 5px;
flex: 0 0 31%;
}
.course-mode .ant-radio{
    display: none;
}
.course-mode .ant-radio-wrapper.ant-radio-wrapper-checked {
    letter-spacing: 0;
    border: 1px solid #fc59bc;
background-color: #ff139e17 !important;
}
.course-mode .ant-radio-group {
    display: flex;
align-content: center;
padding: 10px 0;
margin-right: -5px;
    margin-left: -5px;
}
.course-mode {
    width: 100%;
    float: left;
}
.language-list {
    width: 100%;
    float: left;
    padding: 10px 0;
}
.language-list .ant-select {
    width: 100%;
border: solid 1px #f0f0f0;
box-shadow: 0 3px 6px #00000014;
}
.course-mode.course-list .ant-radio-wrapper {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    flex: 0 0 31%;
    align-items: center;
  }
  .demo-modal-tag .ant-modal-mask {
    z-index: 19998;
  }
  .demo-modal-tag .ant-modal-wrap {
    z-index: 19999;
  }
  .no-slots-course{
    font-size:  14px;
    letter-spacing: 0;
    color: #1c264b;
    opacity: .6;
    width: 100%;
    float: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}