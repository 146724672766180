.report-card{
    width: 100%;
float: left;
background: #fff;
border-radius: 15px;
box-shadow: 0px 1px 5px #d2d2d2;
padding: 0 15px;
padding-top: 30px;
}
.report-btn .ant-form-item-control-input-content {
    justify-content: flex-end;
    display: flex;
  }
.report-card .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: 1px solid #d9d9d9;
  }
.report-card .ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    min-width: 150px;
  }
  .bordered-table {
    border-collapse: collapse;
    border: 1px solid black;
    width: 100%;
  }
  
  .bordered-table th, .bordered-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
  .bordered-table th {
    background-color: #f2f2f2;
    text-align: center; 
    vertical-align: middle; 
  }
  
  