/* .profile-container .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border-radius: 0.475rem;
    border: 0;
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);

} */
.profile-container .card .card-body {
    padding: 2rem 2.25rem;
}
.teacher-profile-container .card .card-header .card-title, .card .card-header .card-title .card-label {
    display: flex;
    align-items: center;
  }
.profile-container .me-7 {
    margin-right: 1.75rem !important;
}

.profile-container .mb-4 {
    margin-bottom: 1rem !important;
}

.profile-container .symbol {
    display: inline-block;
    border-radius: 0.475rem;
}

.profile-container .symbol.symbol-lg-160px.symbol-fixed>img {
    width: 160px;
    height: 160px;
    border-radius: 5px;
}

.profile-container .bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.profile-container .flex-grow-1 {
    flex-grow: 1 !important;
}

.profile-container .justify-content-between {
    justify-content: space-between !important;
}

.profile-container .flex-wrap {
    flex-wrap: wrap !important;
}

.profile-container .fw-bold {
    font-weight: 500 !important;
}

.profile-container .fs-6 {
    font-size: 1.075rem !important;
}

.profile-container .flex-stack {
    justify-content: space-between;
    align-items: center;
}

.profile-container .flex-wrap {
    flex-wrap: wrap !important;
}

.profile-container .d-flex {
    display: flex !important;
}

.profile-container .pe-8 {
    padding-right: 2rem !important;
}

.profile-container .w-sm-300px {
    width: 300px !important;
}

.profile-container .mt-3 {
    margin-top: 0.75rem !important;
}

.profile-container .border-dashed {
    border-style: dashed !important;
    border-color: #e4e6ef;
}

.profile-container .svg-icon {
    line-height: 1;
}

.profile-container .me-2 {
    margin-right: 0.5rem !important;
}

.profile-container .fs-2 {
    font-size: 1.5rem !important;
}

.profile-container .fw-bolder {
    font-weight: 600 !important;
}

.profile-container .text-gray-400 {
    color: #b5b5c3 !important;
}

.profile-container .fw-bold {
    font-weight: 500 !important;
}

.profile-container .fs-6 {
    font-size: 1.075rem !important;
}

.profile-container .min-w-125px {
    min-width: 125px !important;
}

.profile-container .rounded {
    border-radius: 0.475rem !important;
}

.profile-container .py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.profile-container .px-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.profile-container .flex-column {
    flex-direction: column !important;
}

.profile-container .fw-bold {
    font-weight: 500 !important;
}

.profile-container .fs-6 {
    font-size: 1.075rem !important;
}

.profile-container .me-6 {
    margin-right: 1.5rem !important;
}

.profile-container .svg-icon.svg-icon-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
}

.profile-container .svg-icon svg {
    height: 1.15rem;
    width: 1.15rem;
}

.profile-container .mh-50px {
    max-height: 50px !important;
}

.profile-container img, svg {
    vertical-align: middle;
}

.profile-container .svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
    fill: #f1416c;
}

.profile-container .card {
    border: 0;
    box-shadow: 0 0 20px 0 rgba(76, 87, 125, .02);
    border-radius: 5px;
}

.profile-container .symbol {
    display: inline-block;
    border-radius: .475rem;
}

.profile-container .rounded-circle {
    border-radius: 50% !important;
}

.profile-container .text-gray-400 {
    color: #b5b5c3 !important;
}

.profile-container .text-hover-primary {
    transition: color .2s ease, background-color .2s ease;
}

.profile-container .svg-icon {
    line-height: 1;
}

.profile-container .svg-icon.svg-icon-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
}

.profile-container .border-dashed {
    border-style: dashed !important;
}

.profile-container .border-gray-300 {
    border-color: #e4e6ef !important;
}

.profile-container .rounded {
    border-radius: .475rem !important;
}

.profile-container .fw-bold {
    font-weight: 500 !important;
}

.profile-container .nav-line-tabs.nav-line-tabs-2x {
    border-bottom-width: 2px;
}

.profile-container .nav-stretch {
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.profile-container .nav-line-tabs.nav-line-tabs-2x .nav-item {
    margin-bottom: -2px;
}

.profile-container .nav-stretch .nav-item {
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.profile-container .text-gray-900 {
    color: #181c32 !important;
}

.profile-container .text-hover-primary {
    transition: color .2s ease, background-color .2s ease;
}

.profile-container .flex-stack {
    justify-content: space-between;
    align-items: center;
}

.profile-container .fs-2 {
    font-size: 20px !important;
}
.calendar-legendset-conatiner{
    display: flex;
    flex-direction: column;
}
.calendar-legend-set{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:5px 0 ;
}
.legend-square{
width: 20px;
height: 20px;
background-color: #b5b5c3;
margin-right: 10px;
}
.legend-text{
    font-size: 14px;
    color: #b5b5c3;
}