.page-wrapper {
  display: grid;
  grid-template-columns: 335px 1fr;
  border: 1px solid #d2d2d2;
  position: relative;
}
.page-wrapper .teacher-name {
  position: absolute;
  font-size: 14px;
  top: -25px;
}

@media(max-width: 1024px) {
  .page-wrapper {
    grid-template-columns: 300px 1fr;
  }
}

@media (max-width: 820px) {
  .page-wrapper {
    grid-template-columns: 250px 1fr;
  }
}

@media(max-width: 700px) {
  .page-wrapper {
    grid-template-columns: 1fr;
  }
}

.chat-list {
  border-right: 1px solid #d2d2d2;
}

.conversations-loading {
  padding: 1rem;
  text-align: center;
}

/*
  CONV LIST HEADER
-------------------*/
.chat-list__header {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #d2d2d2;
}
.search-container {
  position: relative;
  flex: 1;
}
.search-icon {
  position: absolute;
  right: 8px;
  top: 4px;
}
.chat-list__header input {
  background-color: transparent;
  border: 1px solid #d2d2d2;
  padding: 7px 10px;
  padding-right: 40px;
  border-radius: 5rem;
  font-size: 12px;
  color: black;
  width: 100%;
}
.chat-list__header input::placeholder {
  color: gray;
}
.chat-list__header button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

@media(max-width: 820px) {
  .chat-list__header {
    padding: 12px 10px;
  }
}

/*
  CONV LIST BODY
-----------------*/
.chat-list__body {
  overflow-y: auto;
  height: 75vh;
}
.chat-list__body::-webkit-scrollbar {
  width: 8px;
}

.chat-list__body::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 5px;
}

.chat-list__body::-webkit-scrollbar-track {
    width: 5px;
}
.chat-list__card {
  display: flex;
  gap: 10px;
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #d2d2d2;
  border-right: 5px solid transparent;
}
@media (max-width: 820px) {
  .chat-list__card {
    padding: 10px;
  }
}

.chat-list__card__image {
  height: 56px;
  width: 56px;
  background-color: lightgray;
  border-radius: 50%;
}
@media(max-width: 1024px) {
  .chat-list__card__image { 
    height: 45px;
    width: 45px;
  }
}
@media(max-width: 820px) {
  .chat-list__card__image {
    height: 40px;
    width: 40px;
  }
}

.chat-list__card__image--initials {
  height: 56px;
  width: 56px;
  background-color: lightgray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media(max-width: 1024px) {
  .chat-list__card__image--initials {
    height: 45px;
    width: 45px;
  }
}
@media(max-width: 820px) {
  .chat-list__card__image--initials {
    height: 40px;
    width: 40px;
  }
}

.chat-list__card__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.chat-list__card__content {
  flex: 1;
  position: relative;
}
.chat-list__card__row-one {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  gap: 5px;
}
.chat-list__card__row-one .conv-student-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  max-width: 150px;
}
@media (max-width: 1200px) {
  .chat-list__card__row-one .conv-student-name {
    max-width: 90px;
  }
}

.chat-list__card__row-one .date-time {
  font-size: 12px;
  font-weight: 400;
  color: gray;
}
.chat-list__card__row-two {
  font-size: 12px;
  margin-top: 10px;
  font-weight: 400;
}
.selected-chat-card {
  border-right: 5px solid #1890ff;
  background-color: #f1f1f1;
}
.no-existing-conv {
  font-size: 14px;
  text-align: center;
  margin: 10px;
}

.initialBackColor4CBE73 {
  background-color: #4CBE73 !important;
}
.initialBackColor4C79BE {
  background-color: #4C79BE !important;
}
.initialBackColor7E4CBE {
  background-color: #7E4CBE !important;
}
.initialBackColorBE4C89 {
  background-color: #BE4C89 !important;
}
.initialBackColorBE4C4C {
  background-color: #BE4C4C;
}

/* 
  SINGLE CHAT
---------------*/
@media(max-width: 700px) {
    .single-chat {
    display: none;
  }
}

/*  SINGLE CHAT HEADER  */
.single-chat__header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #d2d2d2;
}
.single-chat__header__left {
  display: flex;
  gap: 10px;
  align-items: center;

}
.single-chat__header__left .course-name {
  font-size: 14px;
  color: gray;
}
.chat-header-image {
  height: 33px;
  width: 33px;
  background-color: lightgray;
  border-radius: 50%; 
}
.chat-header-image--initials {
  height: 33px;
  width: 33px;
  background-color: lightgray;
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-header-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* SINGLE CHAT FOOTER */
.single-chat__footer {
  background-color:  #1C1A23;
  height: 60px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  position: relative;
}
.single-chat__footer button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

/*  SINGLE CHAT BODY  */
.single-chat__body {
  padding: 1rem 2rem;
  height: 70vh;
  overflow-y: auto;
}
.single-chat__body::-webkit-scrollbar {
  width: 8px;
}

.single-chat__body::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 5px;
}

.single-chat__body::-webkit-scrollbar-track {
    width: 5px;
}
@media(max-width: 820px) {
  .single-chat__body {
    padding: 1rem;
  }
}
.chat-row-left {
  display: flex;
  justify-content: flex-start;
}
.chat-row-right {
  display: flex;
  justify-content: flex-end;
}
.chat-right-dark {
  background: #f1f1f1;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  margin-bottom: 1rem;
  max-width: 30vw;
  font-size: 14px;
  border-top-right-radius: 0;
  position: relative;
}
.chat-right-dark::after {
  position: absolute;
  content: "";
  top: 0px;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #f1f1f1;
  border-right: 10px solid transparent;
}
.chat-left-dark {
  background: #f1f1f1;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  margin-bottom: 1rem;
  max-width: 30vw;
  font-size: 14px;
  border-top-left-radius: 0;
  position: relative;
}
.chat-left-dark::before {
  position: absolute;
  content: "";
  top: 0px;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #f1f1f1;
  border-left: 10px solid transparent;
}
.chat-text-message {
  word-break: break-word;
  margin: 0;
}
.message-video-thumbnail {
  height: 170px;
  width: 300px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d0d0d0;
  margin-bottom: 5px;
}

.message-audio-thumbnail, 
.message-audio-thumbnail-transparent {
  height: 70px;
  width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.message-audio-thumbnail {
  background: #d0d0d0;
  justify-content: center;
  align-items: center;
}
.audio-file-name {
  width: 300px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message-image-thumbnail {
  height: 200px;
  width: 300px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d0d0d0;
  position: relative;
  margin-bottom: 5px;
}
.message-image-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}
.message-image-wrapper .expand-icon,
.message-media-wrapper .expand-icon {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  color: red;
  font-size: 1rem;
  background: white;
  padding: 0 7px;
  border-radius: 1rem;
  font-size: 14px;
  border: 1px solid lightgray;
}
.message-media-wrapper .expand-icon {
  right: 25px;
}
.message-image-wrapper img, .message-media-wrapper embed {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1100px) {
  .message-video-thumbnail, .message-image-thumbnail {
    height: 170px;
    width: 200px;
  }
  .message-audio-thumbnail, .message-audio-thumbnail-transparent {
    width: 200px;
  }
  .audio-file-name {
    width: 200px;
  }
}

@media (max-width: 820px) {
  .message-video-thumbnail, .message-image-thumbnail {
    height: 125px;
    width: 150px;
  }
  .message-audio-thumbnail, .message-audio-thumbnail-transparent {
    width: 150px;
  }
  .audio-file-name {
    width: 150px;
  }
}
.media-before-download {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 3rem;
}
.message-media-wrapper {
  height: 100%;
  width: 100%;
}
.message-media-wrapper .message-download-icon {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}
.pdf-details-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  margin: 7px 0;
}
.pdf-details-row__left {
  display: flex;
  align-items: center;
  gap: 5px;
}
.pdf-details-row .pdf-name {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
@media (max-width: 820px) {
  .pdf-details-row .pdf-name {
    width: 75px;
  }
}
.single-message-time {
  color: #AFA9C6;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  margin-top: 7px;
}



/*
  CHAT MESSAGE MEDIA PREVIEW MODAL
-----------------------------------*/
.chat-media-preview-modal-container {
  position: absolute;
  width: 60vw;
  height: 60vh;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: 5px;
  padding: 1rem;
  z-index: 999;
}
.chat-media-preview-modal-container .top-row {
  display: flex;
  justify-content: flex-end;
}
.chat-media-preview-modal-container .top-row .close-btn {
  cursor: pointer;
  position: relative;
  top: -10px;
  right: 0;
  font-size: 2rem;
  color: red;
}
.chat-media-preview-modal-container .image-wrapper {
  width: 100%;
  height: calc(100% - 3rem);
}
.chat-media-preview-modal-container .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.chat-media-preview-modal-container .pdf-wrapper {
  width: 100%;
  height: calc(100% - 3rem);
}
.chat-media-preview-modal-container .pdf-wrapper .pdf {
  height: 100%;
  width: 100%;
}