
.fs{
    font-size:medium !important;
    
}
.loadbtn{
    text-align: center;
}
.info{
  text-align: center;
color: red;  
padding-top: 70px !important;
}
.form-control .form-control-lg .form-control-solid{
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color .2s ease,background-color .2s ease;
    
}
.ftw{
    font-weight: 550 !important;
}
.ant-btn .ant-btn-default .click{
    text-align: right;
}
.topic{
    text-align: center;
}
.align{
    text-align: center; 
}
.ant-select-dropdown.course-list-dropdown{
z-index: 99999;
}
.new-payment-form .ant-form-item-label > label {
    min-width: 160px;
}
.sociallink img
{
    width: 28px;
 height: 28px;
 cursor: pointer;
}
/* .ant-table-cell{
    width: 100% !important;
} */
.totalprice{
    text-align: right;
    padding: 10px;
}s
.select_course{
    width:250px;
}
.currency{
    text-align: right;
    
}
.fnt{
    font-weight: bold;
    padding:15px;
}
.pad{
    padding:15px;
}
.ant-select{
    width:250px;
}
.font{
    font-weight: bold;
    padding:15px;
}
.padd{
    padding:15px;
}
.optionbtn{
    float: right;
}
.refnumber{
    font-weight: bold;
    display: inline-block !important;
    width: 200px !important;
}
.otherPayment{
    font-weight: bold;
    display: inline-block !important;
    width: 300px !important;
}
.reasonRefund{
    padding: 15px 0px !important;
    text-align: right;
    border-right: none !important;
    font-weight: bold;
}
.ant-notification {
    z-index: 99999 !important;
}
.removed-item td {
    background: #ff4d4f7a !important;
}
table#tab_logic input {
    text-align: center;
    padding: 0px;
}

  .ftmodal {
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    padding-top: 60px;
  }
  
  .ft-modal-content {
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }
  
  .ftclose {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .ftclose:hover,
  .ftclose:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .rSpan{
    width: 55px;
    display: inline-block;
  }
  .calDiv{
    white-space: nowrap;
  }
  .walletText{
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
    font-weight: bold;
    font-size: 13px;
  }