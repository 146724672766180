.Dashboard-card-screen {
  width: 100%;
  float: left;
}
.Dashboard-card-screen .card {
  font-size: 14px;
  margin: 15px 0;
  position: relative;
  border-radius: 25px;
  /* border: 2px solid #73AD21; */
}
.Dashboard-card-screen .card .card-dropdown {
  position: absolute;
  top: 8px;
  right: 14px;
}
.Dashboard-card-screen .card .ant-dropdown-trigger {
  font-size: 20px;
}
.card-dropdown-menu .ant-dropdown-menu-item a {
  text-decoration: none;
}
.loadingclass {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.05); */
  border-radius: 4px;
}
.Dashboard-card-screen .teacher-email {
  width: 100%;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Dashboard-card-screen .teacher-list-img {
  border-radius: 50%;
}
.DashboardScreen-container .sort-btn {
  width: 35px;
  height: 35px;
  border: 1px solid #ddd;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 15px;
  cursor: pointer;
}
.DashboardScreen-container .sort-btn.active {
  color: #fff;
  border-color: #1890ff;
  background-color: #1890ff;
}
.addteachbtn {
  color: #fff;
  border-color: #1890ff;
  background-color: #1890ff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 35px;
  border-radius: 2 px;
  margin-top: -24px;
  cursor: pointer;
}


