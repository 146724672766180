.calendarListViewConatiner{
	border-radius: 25px;}
.calendarListViewConatiner .ant-btn.btnEdit {
	border: solid 1px #00A0FF;

}


.date-in-past {
	width: 14.3%;
	background: #ccc;
	border-right: solid 1px #fff;
}

.teachers-slots-container .calendarListViewConatiner {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.createEventBlock {
	font-size: 11px;
	font-weight: 500;

}
.teachers-slots-container .rbc-event, 
.teachers-slots-container .rbc-day-slot .rbc-background-event {
	border-radius: 30px !important;
	text-align: center;
}
.teachers-slots-container .rbc-row-segment {
	text-align: center;
	font-size: 12px;
	margin-top: 5px;
  }
  .teachers-slots-container  .modal-container {
	padding: 0;
	margin: 0; 
	background-color: rgb(0 0 0 / 70%);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9999;
  }
  .teachers-slots-container .cookiesContent {
	width: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fff;
	color: #000;
	text-align: center;
	border-radius: 20px;
	padding: 30px 30px 70px;
  }
  .teachers-slots-container  .cookiesContent button.close {
	width: 30px;
	font-size: 20px;
	color: #c0c5cb;
	align-self: flex-end;
	background-color: transparent;
	border: none;
	margin-bottom: 10px;
  }
  .teachers-slots-container  .cookiesContent img {
	width: 82px;
	margin-bottom: 15px;
  }
  .teachers-slots-container .cookiesContent p {
	margin-bottom: 40px;
	font-size: 18px;
  }
  .teachers-slots-container .cookiesContent button.accept {
	background-color: #ed6755;
	border: none;
	border-radius: 5px;
    min-width: 150px;
	padding: 14px;
	font-size: 16px;
	color: white;
	box-shadow: 0px 6px 18px -5px #ed6755;
  }
  .teachers-slots-container .cookiesContent button.reschdeule {
	background-color: #72B267;
	border: none;
	border-radius: 5px;
    min-width: 150px;
	padding: 14px;
	font-size: 16px;
	color: white;
	box-shadow: 0px 6px 18px -5px #72B267;
  }
  .teachers-slots-container .rbc-event{
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
  }
  .ant-tabs-dropdown{
	z-index: 9999 !important;
  }

  span.rbc-toolbar-label:after {
    content: 'IST';
    margin-left: 10px;
    background: silver;
    padding: 1px 5px 2px 5px;
    height: 100%;
    border-radius: 4px;
}
.copyBtn{
	align-items: right;
	margin-left: 454px;
	margin-top: -24px;
}
.recLink{
	overflow: hidden !important;
	width: 396px;
	height: 20px;
	text-overflow: ellipsis !important;
	white-space: nowrap;

}
/* .calendarListViewConatiner {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.calendarListViewConatiner .viewmodaltitle {
	width: 100%;
	position: relative;
	display: flex;
	padding-right: 16px;
	box-sizing: border-box;
	align-items: center;
	outline: none;
}
.calendarListViewConatiner  .btnDelete{
	position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background-color: transparent;
} */