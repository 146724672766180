
.ant-table-tbody > tr > td {
    border: 1px solid black; 
    border-right: 0px solid #f0f0f0 !important; 
  }
  .ant-table-thead > tr > th {
    border: 1px solid;
    background: #f0f0f0;
  }
  .ant-table {
    border: 1px solid black; 
}

.ant-table-cell-fix-left{
  z-index: 0 !important
}