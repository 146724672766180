.footer {
    background-color: #fff;
    font-size: 13px;
}
.text-dark {
    color: #181c32 !important;
}
.order-md-1 {
    order: 1 !important;
}
.footer .menu, .footer .menu-wrapper {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}
.text-gray-800 {
    color: #3f4254 !important;
}
.menu-gray-600 .menu-item .menu-link {
    color: #7e8299;
    text-decoration: none;
}
.menu-hover-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
    transition: color .2s ease, background-color .2s ease;
    color: #009ef7;
}