.Dashboard-card-screen {
	width: 100%;
	float: left;
  }
  .Dashboard-card-screen .card {
	font-size: 14px;
	margin: 15px 0;
	position: relative;
  }
  .Dashboard-card-screen .card .card-dropdown {
	position: absolute;
	top: 8px;
	right: 14px;
  }
  .Dashboard-card-screen .card .ant-dropdown-trigger {
	font-size: 20px;
  }
  .card-dropdown-menu .ant-dropdown-menu-item a {
	text-decoration: none;
  }
  .Dashboard-card-screen .teacher-email {
	width: 100%;
	float: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
  }
  .Dashboard-card-screen .teacher-list-img {
	border-radius: 50%;
  }
  .Dashboard-card-screen .loadingclass {
	margin: 20px 0;
	margin-bottom: 20px;
	padding: 30px 50px;
	text-align: center;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 4px;
  }
  .DashboardScreen-container .sort-btn.active {
	color: #fff;
	border-color: #1890ff;
	background-color: #1890ff;
  }
  .addteachbtn {
	color: #fff;
	border-color: #1890ff;
	background-color: #1890ff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 110px;
	height: 35px;
	border-radius: 2px;
	margin-top: 10px !important ;
	margin-bottom: 50px !important;
	cursor: pointer;
  }
  
  .fontradio {
	padding: 15px;
  }
  .paddradio {
	padding: 15px;
	margin-left: 152px !important;
	display: block;
	width: 200px !important;
	margin-top: 45px !important;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	appearance: none;
	border-radius: 0.375rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .resetbtn {
	border-color: #1890ff !important;
	background: #1890ff !important;
	color: #fff !important;
	
  }
  .cursor{
	cursor: pointer;
  }  