 .aside {
    width: 265px;
    transition: width .3s ease;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
    overflow: hidden;
    
    }
    .aside .aside-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
        padding: 0 25px
    }

    .aside .aside-menu {
        width: 265px,
        
    }

    .aside-fixed .aside {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 101;
        overflow: hidden
    }

    .aside-static .aside {
        position: relative;
        z-index: 1
    }
    .aside {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 28px 0 rgba(82, 63, 105, .05);
    padding: 0
}

.aside .btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0
}

.aside .btn-custom .btn-label {
    transition: opacity .3s ease;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    opacity: 1
}

.aside .btn-custom .btn-icon {
    display: inline-block;
    transition: opacity .3s ease;
    overflow: hidden;
    opacity: 0;
    margin: 0;
    width: 0
}

.aside.aside-dark {
    background-color: #1e1e2d
}

.aside.aside-dark .aside-logo {
    background-color: #1a1a27
}

.aside.aside-dark .aside-toggle svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #494b74
}

.aside.aside-dark .aside-toggle svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.aside.aside-dark .separator {
    border-bottom-color: #282a3d
}

.aside.aside-dark .btn-custom {
    color: #b5b5c3;
    background-color: rgba(63, 66, 84, .35)
}

.aside.aside-dark .btn-custom.active,
.aside.aside-dark .btn-custom.show,
.aside.aside-dark .btn-custom:active:not(.btn-active),
.aside.aside-dark .btn-custom:focus:not(.btn-active),
.aside.aside-dark .btn-custom:hover:not(.btn-active),
.btn-check:active+.aside.aside-dark .btn-custom,
.btn-check:checked+.aside.aside-dark .btn-custom,
.show>.aside.aside-dark .btn-custom {
    color: #b5b5c3;
    background-color: rgba(63, 66, 84, .35) !important
}

.aside.aside-dark .btn-custom .btn-icon {
    color: #b5b5c3
}

.aside.aside-dark .btn-custom .btn-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #b5b5c3
}

.aside.aside-dark .btn-custom .btn-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.aside.aside-light {
    background-color: #fff
}
.aside .aside-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    padding: 0 25px;
}
.h-25px {
    height: 25px!important;
}
.aside.aside-dark .aside-logo {
    background-color: #1a1a27;
}


.Sidebar-Menu-Container .ant-menu-submenu {
        background-color: #1e1e2d;
}
.Sidebar-Menu-Container .ant-menu-sub.ant-menu-inline {
        background-color: #1e1e2d;
}
.Sidebar-Menu-Container .ant-menu-item-group-title {
    color: #9899ac;
        font-size: 13px;
}
.Sidebar-Menu-Container .ant-menu-inline.ant-menu-root .ant-menu-item {
    color: #9899ac;
        margin: 0;
}
.Sidebar-Menu-Container .ant-menu-inline.ant-menu-root .ant-menu-submenu-title,
.Sidebar-Menu-Container .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title
 {
    color: #9899ac;
}
.Sidebar-Menu-Container .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title,
.Sidebar-Menu-Container .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
    color: #fff;
}
.Sidebar-Menu-Container .ant-menu-inline {
    background-color: #1e1e2d;
}
.Sidebar-Menu-Container .ant-menu.ant-menu-root {
    height: calc(100vh - 65px);
    border: none;
}
.Sidebar-Menu-Container .ant-menu-vertical .ant-menu-item::after, 
.Sidebar-Menu-Container .ant-menu-vertical-left .ant-menu-item::after, 
.Sidebar-Menu-Container .ant-menu-vertical-right .ant-menu-item::after, 
.Sidebar-Menu-Container .ant-menu-inline .ant-menu-item::after {
    display: none;
}
.Sidebar-Menu-Container .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    transition: color .2s ease,background-color .2s ease;
    background-color: #1b1b28;
    color: #fff;
}
.Sidebar-Menu-Container .ant-menu-item:active, 
.Sidebar-Menu-Container .ant-menu-submenu-title:active {
   background-color: #1b1b28; 
}
.Sidebar-Menu-Container .ant-menu-submenu-expand-icon,
.Sidebar-Menu-Container .ant-menu-submenu-arrow,
.Sidebar-Menu-Container .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-arrow{
    color: #9899ac;
}
.Sidebar-Menu-Container .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-expand-icon,
.Sidebar-Menu-Container .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-arrow,
.Sidebar-Menu-Container .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open  .ant-menu-submenu-arrow{
    color: #fff;
}
.Sidebar-Menu-Container .ant-menu-submenu:hover .ant-menu-submenu-title,
.Sidebar-Menu-Container .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu:hover .ant-menu-submenu-title,
.Sidebar-Menu-Container .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.Sidebar-Menu-Container .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #fff;
}
.Sidebar-Menu-Container .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.Sidebar-Menu-Container .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content,
.Sidebar-Menu-Container .ant-menu-inline.ant-menu-root .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-title-content {
    font-size: 13px;
}
.Sidebar-Menu-Container .ant-menu-inline.ant-menu-root .ant-menu-submenu-title .ant-menu-title-content{
    font-size: 14px;
}
@media (max-width:991.98px) {
    /* .aside {
        display: none
    }

    .aside .aside-logo {
        display: none
    }
    .Sidebar{
        display: none;
    } */
    
}

.Sidebar-Menu-Container  .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    color: #fff;
    width: 40px;
    text-align: center;
  }

/* .Sidebar-Menu-Container  .toggle-btn {
    display: none;
  } */
  .SideMenuIcon {
    color: #fff;
    line-height: 58px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    background-color: #1A1A27;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 0;
}
.aside,.page-layout.wrapper {
    
    transition: all ease .3s;
}
.menu-close .aside {
    left: -270px;
    transition: all ease .3s;
    display: block;
}
.menu-close .page-layout.wrapper {
    padding-left: 0;
    transition: all ease .3s;
}
.menu-close .header-fixed .header {
    left: 0;
}
.header-menu .Header-menu-Icon {
    display: block;
    position: relative;
    left: -150px;
    width: 0;
    transition: all ease .1s;
}
.header-menu .Header-menu-Icon .SideMenuIcon {
    margin-top: 12px;
}
.menu-close .header-menu .Header-menu-Icon {
    position: relative;
    left: 0;
    width: inherit;
    transition: all ease .3s;
}
