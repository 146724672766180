
.ant-card{
position: relative;
border:"1px solid pink";
background-color: lightgrey;
width: 270px;
height: 478px;
border-radius: 30px;
overflow: hidden;
margin: 1rem 0px;
color: white;
}

.overlay {
    position: absolute;
    top: 226px;
    left: 0;
    width: 100%;
    height: 52%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
   font-weight: 800;
    transition: opacity 0.3s ease;
    pointer-events: none; 
}

.customCard:hover .overlay {
    opacity: 1;
    pointer-events: none; 
}











