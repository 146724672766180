.table-container {
    max-height: 400px; 
    overflow-y: auto;
    position: relative;
  }
  
  .table-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-container thead th {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1; 
  }
  
  .table-container tbody td {
    border-top: 1px solid #dee2e6;
  }
  
  .table-container tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  