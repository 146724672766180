.header-fixed .wrapper {
	padding-top: 65px
}
  .header-hidden{
    height: 4rem;
    width: 100%;
    z-index: 40; 
    /* border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08); */
    position: sticky;
    top: -80px;
	transform: translateY(-100%);
    /* transition: 0.3s linear;
	 */
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 2;
	height: 65px;
	/* transition: left .3s ease; */

}

.header-fixed .header {
	position: fixed;
	top: 0;
	right: 0;
	left: 265px;
	border-bottom: 1px solid #eff2f5;
	background-color: #fff; 
	/* -webkit-transition: all .3s ease-in-out; */

}

.header-menu {
	display: flex;
}

.menu-item {
	
	display: flex;
	align-items: center;
}
.header .menu-item{
	display: block;
}
.header .ant-menu-title-content{
	display: block;	
	}
 .menu-item .menu-link {
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 0;
	flex: 0 0 100%;
	padding: 0.65rem 1rem;
	transition: none;
	outline: 0 !important;
	color: #5e6278;
	transition: color .2s ease, background-color .2s ease;
	border-radius: 5px;
	font-size: 14px;
}

.menu-item.active .menu-link {
	transition: color .2s ease, background-color .2s ease;
	color: #009ef7;
	background-color: rgba(245, 248, 250, .8);
}
 .page-header-container .ant-dropdown-link {
   display: flex;
   align-items: center;
 }
.page-header-container .ant-dropdown-link img{
   width: 40px;
   height: 40px;
   border-radius: 100%;
}
.header-dropdown-menu .menu-item {
   display: block;
   padding: 0;
}
.header-dropdown-menu .menu-item .menu-content {
   padding: 0.65rem 1rem;
}
.header-dropdown-menu .symbol {
   display: inline-block;
   flex-shrink: 0;
   position: relative;
   border-radius: 0.475rem;
}
.me-5 {
   margin-right: 1.25rem !important;
}
.header-dropdown-menu .symbol.symbol-50px>img {
   width: 50px;
   height: 50px;
   border-radius: 100%;

}
.fs-14 {
   font-size: 14px;
}
.fs-12 {
   font-size: 12px;
}
.text-muted {
   color: #a1a5b7 !important;
}
.header-dropdown-menu .text-hover-primary {
   transition: color .2s ease, background-color .2s ease;
}
.fs-7 {
   font-size: .95rem !important;
}
.separator {
   display: block;
   height: 0;
   border-bottom: 1px solid #eff2f5;
}
.menu-gray-800 .menu-item .menu-link {
   color: #3f4254;
}
.header-dropdown-menu .menu-item .menu-link {
   cursor: pointer;
   display: flex;
   align-items: center;
   padding: 0;
   flex: 0 0 100%;
   padding: 0.35rem 1rem;
   transition: none;
   outline: 0 !important;
   font-size: 14px;
}
.header-dropdown-menu .menu-sub-dropdown {
   border-radius: 0.475rem;
   background-color: #fff;
   box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
   z-index: 105;
}
.header-dropdown-menu a {
   text-decoration: none !important;
}
@media (max-width:991.98px) {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 55px;
		position: relative;
		z-index: 3
	}

	.header-tablet-and-mobile-fixed .header {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 100;
		height: 55px;
		min-height: 55px;
		background-color: #fff;
		box-shadow: 0 10px 30px 0 rgba(82, 63, 105, .05)
	}

	.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .header {
		box-shadow: none
	}

	.header .header-menu {
		display: none;
		z-index: 99
	}

	/* .header .page-title {
		display: none !important
	}
	.header .menu-item{
		display: none;
	}
	.header .ant-menu-title-content{
	display: none;	 */
	/* }  */
}
/* @media (min-width: 576px){
	.header .menu-item{
		display: none;
	}
	.header .ant-menu-title-content{
	display: none;	
	} 
} */