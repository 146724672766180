/* Styling for the container holding the cards */
.report-container {
    margin-top: 20px;
    display: grid; /* Use grid for responsive layout */
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjusts columns based on screen size */
    gap: 16px; /* Space between the cards */
  }
  
  /* Individual card styling */
  .report-card {
    background-color: #ffffff; /* White background for the card */
    border-radius: 8px; /* Smooth rounded corners */
    padding: 16px; /* Internal spacing */
    color: #333333; /* Darker text for readability */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05); /* Light, elegant shadow */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  /* Hover effect for cards */
  .report-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.1); /* Slightly darker shadow */
  }
  
  /* Title and text inside the card */
  .report-card p {
    margin: 5px 0; /* Spacing between paragraphs */
    font-size: 0.85rem;
    color: #555555; /* Subtle gray text */
  }
  
  /* Strong text styling for labels */
  .report-card p strong {
    color: #000000; /* Black text for strong emphasis */
  }
  
  /* Button styling */
  .report-button {


    color: #1890FF; /* White text on the button */
    background-color:  #ffffff; 
    border: none; 
    /* border-radius: 5px;  */
    font-size: 0.85rem;
    cursor: pointer;
    /* transition: background-color 0.3s ease, transform 0.2s ease; */
  }
/*   
  .report-button:hover {
    background-color: #0a6cd4; 
    transform: scale(1.05); 
  }
   */