.modalTable{
    width: fit-content !important;
    margin-left: 337px;
    position: absolute;
  }
  .gray-cell {
    color: gray;
  }
  .teacherDetails{
    width: fit-content !important;
  
  }
  .report-card{
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 15px;
  
    box-shadow: 0px 1px 5px #d2d2d2;
    padding: 0 15px;
   
    padding-top: 30px;
   
  }
  /* style.css */
  .custom-table {
    width: 100%; 
    border-collapse: collapse; /* Collapse table borders */
    border: 1px solid #ccc; /* Add a border around the table */
  
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ccc; /* Add borders to table cells */
    padding: 8px; /* Add padding to table cells for spacing */
    text-align: center; /* Center-align text in cells */
  }
  
  .custom-table th {
    background-color: #f2f2f2;
    width: 12.5%;
   /* Background color for table header cells */
  }
  .batchDetail{
    margin-top: 10px;
        border: 1px solid darkgray;
        padding: 3px;
        padding-top: 9px;
        width: 101%;
        margin-left: -4px;
        background-color: aliceblue;
  }
  .batchTableView{
    border: 2px solid darkgray;
  /* margin: 140px; */
  
  
      padding-left: 11px;
  
      padding-right: 13px;
  padding-bottom: 40px;
  }
  .full-page-view {
    position: fixed;
    top: 0;
    height: 100%;
    background-color: white;
    /* z-index: 9999; */
    display: flex;
    overflow: auto;
  }


  
  .batch-details-content {
   height: 100%;
    padding: 20px;
    /* max-width: 800px; */
    width: 100%;
    padding-top: 120px;
    background-color: white;
    border-radius: 5px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  }
  /* Add this CSS to your stylesheet or style tag */
.student-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  .modal-body-scrollable {
    max-height: 400px; 
    overflow-y: auto; 
  }
  .batch-box{
    display: block;
    font-size: 8px;
    font-weight: bold;
  }
  
  .hover-effect:hover {
    background-color: darkgrey; 
  }
  
  