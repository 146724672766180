.hover-effect:hover {
    background-color: darkgrey; 
  }

  .blink {
    animation: blink-animation 1s steps(5, start) infinite;
  }
  
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  
  .redButton {
    background-color: red;
    border-color: red;
    color: white;
  }
  
  .greenButton {
    background-color: green;
    border-color: green;
    color: white;
  }

  .pagi-contaner ul{
    margin-left: 0px !important;
  }
  
.leads-section{
  display: flex;
  justify-content: flex-end;
}
.paid_demo{
  color: white;
  background: red;
  display: block;
  text-transform: capitalize;
  margin: 0px 10px;
  font-weight: bold;
  font-size: 9px;
}
.center{
  display: flex;
  justify-content: center;
}
.container {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 10px ;
}
