.calendarListViewConatiner{
	border-radius: 25px;}
.calendarListViewConatiner .ant-btn.btnEdit {
	border: solid 1px #00A0FF;

}


.date-in-past {
	width: 14.3%;
	background: #ccc;
	border-right: solid 1px #fff;
}

.teachers-slots-container .calendarListViewConatiner {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.createEventBlock {
	font-size: 11px;
	font-weight: 500;
	text-align: center;
}
.teachers-slots-container .rbc-event, 
.teachers-slots-container .rbc-day-slot .rbc-background-event {
	border-radius: 30px !important;
	text-align: center;
}
.teachers-slots-container .rbc-row-segment {
	text-align: center;
	font-size: 12px;
	margin-top: 5px;
  }
  .teachers-slots-container  .modal-container {
	padding: 0;
	margin: 0; 
	background-color: rgb(0 0 0 / 70%);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9999;
  }
  .teachers-slots-container .cookiesContent {
	width: 600px;
	display: flex;
	flex-direction: column;
	align-items: left;
	background-color: #fff;
	color: #000;
	text-align: left;
	border-radius: 20px;
	padding: 30px 30px 70px;
  }
  .teachers-slots-container  .cookiesContent button.close {
	width: 30px;
	font-size: 20px;
	color: #c0c5cb;
	align-self: flex-end;
	background-color: transparent;
	border: none;
	margin-bottom: 10px;
  }
  .teachers-slots-container  .cookiesContent img {
	width: 82px;
	margin-bottom: 15px;
  }
  .teachers-slots-container .cookiesContent p {
	margin-bottom: 40px;
	font-size: 18px;
  }
  .teachers-slots-container .cookiesContent button.accept {
	background-color: #ed6755;
	border: none;
	border-radius: 5px;
    min-width: 150px;
	padding: 14px;
	font-size: 16px;
	color: white;
	box-shadow: 0px 6px 18px -5px #ed6755;
  }
  .teachers-slots-container .rbc-event{
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
  }
  .teachers-slots-container .viewmodaltitle,
  .teachers-slots-container .viewmodaldescription,
  .teachers-slots-container .viewmodalstatus{
	text-align: left;
width: 100%;
float: left;
}
.teachers-slots-container .SpinBoolean{
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
}
.student-grid .student-teacher-list{
	width: 100%;
    float: left;
}
.student-grid .student-teacher-list .card{
	width: 100%;
    float: left;
    background-color: rgb(231 229 229 / 53%);
    border-radius: 25px;
}
.student-checkbox{
	position: absolute;
    top: 20px;
    right: 20px;
}
.buttonbox{
	cursor: pointer;
}
.Button{
	background-color: lightblue;
}
.ButtonState{
	color: black !important;
}
.load{
	text-align: center;
}
.boxSize{
	margin-top: 20px;
}
.ant-pagination-item {
	border-radius: 30px !important;
}
.pagination{
	float: right !important;
	margin-top: 61px;
}
.ant-pagination{
	margin-left: 832px;
	margin-top: 43px;
}
.singleCard{
	text-align: center;
	margin-top: 50px;
	font-weight: 800;
}
.table-cell {
	border: 1px solid #000;
	padding: 8px;
  }
  
  th.table-cell {
	text-align: left;
  }
  
.TeacherStudent-card{
	
		width: 100%;
		float: left;
		background: #fff;
		border-radius: 15px;
	
		box-shadow: 0px -11px 19px #d2d2d2;
		padding: 0 15px;
	
		padding-top: 16px;
	
}
.no-slots-message {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
  }
  .disabled-btn {
	cursor: not-allowed;
	opacity: 0.5;
	
  }
  .custom-tables {
	width: 100%; 
	border-collapse: collapse; 
	border: 1px solid #ccc; 
  
  }
  
  .custom-tables th, .custom-tables td {
	border: 1px solid #ccc; 
	padding: 7px; 
	text-align: center; 
  }
  
  .custom-tables th {
	background-color: #f2f2f2;
	width: 11.111%;
  
  }
  @keyframes blink {
	0% {
	  opacity: 1;
	}
	50% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  }
  
  .blinking-icon {
	animation: blink 1s infinite;
  }
  
/* .ant-pagination-item-link{
	border-radius: 30px !important;
} */
/* .calendarListViewConatiner {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.calendarListViewConatiner .viewmodaltitle {
	width: 100%;
	position: relative;
	display: flex;
	padding-right: 16px;
	box-sizing: border-box;
	align-items: center;
	outline: none;
}
.calendarListViewConatiner  .btnDelete{
	position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background-color: transparent;
} */
/* .ant-modal-content{
	width: 182% !important;
	margin-left: -181px;
} */

/* 
.rbc-day-slot .rbc-event-label {
    margin-top: 91px;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    padding-right: 5px;
    width: auto;
} */
/* Style the table to take full width */
.DashboardScreen-container {
	width: 100%;
	background-color: #f4f4f4; /* Optional: Background color for the container */
  }
  
  /* Table should take up full width */
  .student-grid {
	width: 100%;
	border-collapse: collapse; /* Ensures borders don't overlap */
	table-layout: fixed; /* Ensures equal width for columns */
	background-color: #ffffff; /* Background color of the table */
  }
  
  /* Style table header */
  .student-grid th {
	background-color: #f0f0f0; /* Light background color for header */
	padding: 10px;
	text-align: left;
  }
  
  /* Style table cells */
  .student-grid td {
	padding: 10px;
	text-align: left;
	background-color: #ffffff; /* Background color of cells */
	border-bottom: 1px solid #ddd; /* Optional: Add borders between rows */
  }
  
  /* Highlight row on hover */
  .student-grid tr:hover {
	background-color: #f9f9f9; /* Light background color when row is hovered */
  }
  
  .table-cell {
	word-wrap: break-word; /* Ensures text wraps correctly in cells */
	padding: 10px;
  }
  
  /* Add a consistent font and color for the table text */
  .student-grid {
	font-family: Arial, sans-serif;
	color: #333;
  }
  
  /* Optional: Adding borders around the table cells for clarity */
  .student-grid th,
  .student-grid td {
	border: 1px solid #ddd;
  }
  
  /* Custom style for highlighted text inside the Link */
  .highlight {
	color: red; /* Change to the desired color */
  }
  