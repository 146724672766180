/* .webinar-card{
    width: 100%;
float: left;
background: #fff;
border-radius: 15px;
box-shadow: 0px 1px 5px #d2d2d2;
padding: 0 15px;
height: 1045px;
margin-top: 15px;
} */
.banner {
    margin: 1rem 0;
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    height: 150px;
}

.webinar-filter{
    padding-top: 15px !important;
}
  .banner-user-elem{
    text-align: center;
    color: black;
    position: relative;
    overflow: hidden;
  }

  .banner-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .banner-content {
    position: absolute !important;
    left: 0;
    height: 100%;
    width: 100%;
    top: 0;
    background: rgba(0,0,0,0.7);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3rem;
  }

  .banner-content .banner-user-title{
    font-size: 16px;
    margin-bottom: 10px;
   
  }
  .banner-content .banner-user-text{
    font-size: 14px;
  }

  .banner-anim-elem .bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    overflow: hidden;
 border-radius: 30px !important;
  }
  .banner-anim-thumb-default span.active {
    background: white !important;
  }
  .banner-anim{
    margin-top: 10px;
    border-radius: 30px !important;
  }
  .banner-button-row {
    display: flex;
    justify-content: flex-end;
  }

  .banner-button-row button {
    border: 0px;
    outline: 0px;
  }
  