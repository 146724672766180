.modalTable{
  width: fit-content !important;
  margin-left: 337px;
  position: absolute;
}
.gray-cell {
  color: gray;
}
.teacherDetails{
  width: fit-content !important;

}
.custom-tables {
  width: 100%; 
  border-collapse: collapse; 
  border: 1px solid #ccc; 

}

.custom-tables th, .custom-tables td {
  border: 1px solid #ccc; 
  padding: 7px; 
  text-align: center; 
}

.custom-tables th {
  background-color: #f2f2f2;
  width: 11.111%;

}