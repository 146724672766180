.login-page-container .login-bg-container {
    background-attachment: fixed;
        background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
    height: 100vh;
}
.login-page-container .p-10 {
    padding: 2.5rem !important;
}
.login-page-container .pb-lg-20 {
    padding-bottom: 5rem !important;
}
.login-page-container .mb-12 {
    margin-bottom: 3rem !important;
}
.login-page-container .h-40px {
    height: 40px !important;
}
.login-page-container .bg-body {
    --bg-color: 255, 255, 255;
    background-color: #fff !important;
}
.login-page-container .p-lg-15 {
    padding: 3.75rem !important;
}
.login-page-container .w-lg-500px {
    width: 500px !important;
}
.login-page-container .rounded {
    border-radius: 0.475rem !important;
}
.login-page-container .mb-10 {
    margin-bottom: 2.5rem !important;
}
.login-page-container .h1, .login-page-container h1 {
    font-size: 22px !important;
}
.login-page-container .text-gray-400 {
    color: #b5b5c3 !important;
}
.login-page-container .fw-bold {
    font-weight: 500 !important;
}
.login-page-container .fs-4 {
    font-size: 16px;
}
.login-page-container .fw-bolder {
    font-weight: 600 !important;
}
.login-page-container .fv-plugins-icon-container {
    position: relative;
}
.login-page-container .form-control.form-control-solid {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color .2s ease, background-color .2s ease;
}
.login-page-container .fs-6 {
    font-size: 15px;
}
.login-page-container .form-control-lg {
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: 0.475rem;
}
.login-page-container .flex-center {
    justify-content: center;
    align-items: center;
}
.login-page-container .h-20px {
    height: 20px !important;
}
.login-page-container .me-3 {
    margin-right: 0.75rem !important;
}
.login-page-container .link-primary,
.login-page-container .link-primary:focus,
.login-page-container .link-primary:hover {
    color: #33b1f9;
    margin-left: 10px;
}
.login-page-container .login-social-btn {
    font-size: 16px;
}
.login-page-container .flex-stack {
    justify-content: space-between;
    align-items: center;
}
.login-page-container .ant-form-vertical .ant-form-item-label > label {
    color: #181c32 !important;
        font-weight: 600 !important;
            font-size: 15px;
}
.login-page-container .ant-input-affix-wrapper {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    padding: 0 15px;
    border-radius: 5px;
}
.login-page-container .ant-input{
        background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color .2s ease, background-color .2s ease;
    min-height: calc(1.5em + 1rem + 2px);
    padding: 0.825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: 0.475rem;
        padding: 0 1.5rem;
}
.login-page-container .ant-btn-primary{
    width: 100%;
        padding: 0.5rem 1rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    height: inherit;
    font-size: 16px;
}
.login-page-container .ant-form-item-control-input-content {
    position: relative;
}
.login-page-container .login-form-forgot {
    position: absolute;
    top: -30px;
    right: 0;
}
.login-page-container .ant-input-affix-wrapper > input.ant-input {
    min-height: calc(1.5em + 1rem + 0px);
}