.dashboard-container {
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
}

.kpi-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.kpi-card {
  background-color: #f8f9fa;
  border: 1px solid #e2e2e2;
  padding: 20px;
  text-align: center;
  width: 22%;
  color: #333;
  box-sizing: border-box;
  margin: 10px;
}

.kpi-card h3 {
  margin: 10px 0;
  font-size: 1.2em;
}

.kpi-card p {
  font-size: 1em;
}

@media (max-width: 1200px) {
  .kpi-card {
    width: 30%;
  }
}

@media (max-width: 900px) {
  .kpi-card {
    width: 45%;
  }
}

@media (max-width: 600px) {
  .kpi-card {
    width: 100%;
    padding: 10px;
  }

  .kpi-card h3 {
    font-size: 1em;
  }

  .kpi-card p {
    font-size: 0.9em;
  }
}

.filters-container {
  margin-bottom: 16px;
  margin-right: auto;
}

/* Table container with scrolling */
.table-container {
  width: var(--table-width, 100%);
  max-width: 100%;

  overflow: auto; /* Enables horizontal and vertical scrolling */

position: relative;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto; 
  overflow-y: auto;
  max-height: none !important;
}


/* Ensures the table header stays fixed during vertical scroll */
.ant-table-thead > tr > th {
  background-color: #fff; /* Ensures the header has a solid background */
  position: sticky;
  top: 0;
  z-index: 2; /* Keeps the header above the table body */
}

/* Table layout */
.ant-table {
  min-width: 1340px; /* Prevents the table from shrinking too much */
  /* Ensures the columns maintain their width */

}

.ant-table-cell {
  white-space: nowrap; 
}

.ant-pagination {
  margin-top: 16px;
  text-align: center;
}

@media (max-width: 768px) {
  .ant-table {


    min-width: 700px; 

    position: fixed;
    height: auto;
  }

  .table-container {
    padding: 0 10px; 
  }


}

